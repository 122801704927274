const server = {
  API_METHOD_DELETE: "delete",
  API_METHOD_GET: "get",
  API_METHOD_PATCH: "patch",
  API_METHOD_POST: "post",
  API_METHOD_PUT: "put",

  API_URL_ENGAGE: "/engage",
  API_URL_EMPOWER: "/empower",
  API_URL_CONNECT: "/connect",

  API_URL_EVENT: "/empower/event",
  API_URL_EVENTS: "/empower/events",

  API_URL_FAIRS: "/fairs",
  API_URL_FAIRS_UPCOMING: "/fairs/upcoming",
  API_URL_FAIR: "/fair",
  API_URL_FAIR_APPLY: "/fair/apply",
  API_URL_FAIRS_APPLIED: "/fairs/applied",
  API_URL_FAIRS_ADMIN_DRAFT: "/fair/draft",
  API_URL_FAIRS_ADMIN_PUBLISH: "/fair/publish",

  API_URL_FORUM_THEMES: "/forum/themes",
  API_URL_FORUM_THEME: "/forum/theme",
  API_URL_FORUM_POST: "/forum/post",

  API_URL_INVITATION: "/invitation",
  API_URL_INVITATIONS: "/invitations",
  API_URL_INVITATIONS_NEW: "/invitations/new",

  API_URL_VOLUNTEERING_INVITATIONS: "/volunteering/invitations",
  API_URL_VOLUNTEERING_INVITATION: "/volunteering/invitation",
  API_URL_VOLUNTEERING_CREATE: "/volunteering/create-account",
  API_URL_VOLUNTEER: "/volunteer",
  API_URL_VOLUNTEER_PICTURE: "/volunteer/profile-picture",

  API_URL_VOLUNTEERING_OPPORTUNITIES: "/volunteering/opportunities",
  API_URL_VOLUNTEERING_OPPORTUNITIES_LATEST: "/volunteering/opportunities/latest",
  API_URL_VOLUNTEERING_OPPORTUNITIES_MY: "/volunteering/opportunities/my",
  API_URL_VOLUNTEERING_OPPORTUNITY: "/volunteering/opportunity",

  API_URL_MEMBER: "/member",
  API_URL_MEMBER_PICTURE: "/member/profile-picture",
  API_URL_MEMBER_CREATE: "/member/create-account",
  API_URL_MEMBERS: "/members",

  API_URL_ORGANIZATIONS: "/organizations",
  API_URL_ORGANIZATION: "/organization",
  API_URL_ORGANIZATION_OVERVIEW: "/application-overview",
  API_URL_ORGANIZATION_JOINED: "/organizations/recently-joined",
  API_URL_ORGANIZATION_COMMUNITY: "/organizations/community",
  API_URL_ORGANIZATION_REGISTER: "/organization/apply",
  API_URL_ORGANIZATION_RESOLVE: "/organization/resolve",
  API_URL_ORGANIZATION_VERIFY: "/organization/verify-email",

  API_URL_NEWS: "/articles",
  API_URL_NEWS_NEW: "/article/submit",
  API_URL_NEWS_MY: "/articles/my",
  API_URL_NEWS_LATEST: "/articles/latest",
  API_URL_NEWS_RESOLVE: "/article/resolve",
  API_URL_NEWS_ADMIN: "/articles/review",
  API_URL_NEWS_ADMIN_NEW: "/article/admin-publish",

  API_URL_USER_EMAIL_VERIFY: "/user/is-email-available",
  API_URL_USER_FIND: "/user/find-account",
  API_URL_USER_LOGIN: "/user/login",
  API_URL_USER_PASSWORD_REQUEST_CHANGE: "/user/change-password",
  API_URL_USER_PASSWORD_CHANGE_VERIFY_CODE: "/user/verify-password-change",
  API_URL_USER_PASSWORD_NEW: "/user/set-new-password",
  API_URL_USER: "/user",

  API_URL_EMAIL_POT: "/email/pot",
  API_URL_EMAIL_CONTACT: "/email/contact",

}

export default server;