/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback } from 'react';

const Pager = ({
  page = 0,
  numberOfItems = 0,
  itemsPerPage = 20,
  onPageChange = (val) => {}
}) => {
  const getTotalPages = useCallback(() => {
    if (itemsPerPage > numberOfItems) {
      return 1;
    }
    return Math.ceil(numberOfItems/itemsPerPage);
  }, [numberOfItems, itemsPerPage]);

  const RenderPages = useCallback(() => {
    const totalPages = getTotalPages();
    const humanPage = page + 1;
    const temp = [];
    temp.push(
      <li className={(humanPage - 1 < 1) ? 'page-item disabled' : 'page-item'}>
        <a className='page-link' onClick={() => onPageChange(page - 1)}>&laquo;</a>
      </li>
    )
    temp.push(
      <li className='page-item active'>
        <a className='page-link' onClick={() => onPageChange(page)}>{humanPage}</a>
      </li>
    )
    temp.push(
      <li className={(humanPage + 1 > totalPages) ? 'page-item disabled' : 'page-item'}>
        <a className='page-link' onClick={() => onPageChange(page + 1)}>&raquo;</a>
      </li>
    )
    return temp;
  }, [page]);

  return (
    <ul class="pagination align-items-center justify-content-center mt-4">
      <RenderPages />
    </ul>
  )
}

export default Pager;